import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, ItemCard, Loader } from "../modules";
import { SvgArrow } from "../assets/svg";

type Shares = {
  id: number;
  name: string;
  price?: number;
  weight?: string;
  description: string;
  img: string;
  share: boolean;
}[];

export function SharesPage() {
  const navigate = useNavigate();
  const [shares, setShares] = useState<Shares>();
  const [message, setMessage] = useState<string>();
  useEffect(() => {
    window.scroll(0, 0);
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API! + "?test-error=1")
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setShares(json.data);
        } else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => setMessage("Ошибка: " + err.message));
    document.title = `Fix Eat | Акции`;
  }, []);
  return (
    <Container>
      <div className="category">
        <SvgArrow style={{ rotate: "270deg", marginRight: "20px" }} onClick={() => navigate(`/`)} />
        <img
          src={"https://cdn-icons-png.flaticon.com/512/2652/2652181.png"}
          width={50}
          style={{ marginRight: "20px" }}
          alt="sharesPicture"
        />
        <div style={{ display: "flex", flexDirection: "column", marginRight: "auto" }}>
          <h4>Все акции</h4>
          {shares ? <h5>Акций: {shares.length}</h5> : <h5>Акций нет</h5>}
        </div>
      </div>
      {shares ? (
        shares.map((item) => <ItemCard key={item.id} {...item} />)
      ) : message ? (
        <h1 style={{ padding: "100px 0" }}>{message}</h1>
      ) : (
        <Loader />
      )}
    </Container>
  );
}
