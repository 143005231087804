import { useState } from "react";
import { CustomButton } from "./CustomButton";
import { useNavigate } from "react-router-dom";
import { Loader } from "./Loader";

export function ItemCard({
  id,
  name,
  price = 0,
  new_price = 0,
  discount = "",
  img,
  weight = "",
  description,
  share,
  style = {},
}) {
  const navigate = useNavigate();
  const link = share ? "shares" : "item";
  const [load, setLoad] = useState(true);
  return (
    <div className="item-card" style={style} onClick={() => navigate(`/${link}/${id}`)}>
      <div className="flex-start">
        {load ? <Loader style={{ marginRight: "10px" }} /> : <></>}
        <img
          onLoad={() => setLoad(false)}
          style={load ? { display: "none" } : {}}
          src={process.env.REACT_APP_URL + img}
          width={"120px"}
          height={"100%"}
          alt="itemPicture"
        />
        {discount && (
          <div className="discount">
            <span>
              <p>{"-" + discount}</p>
            </span>
          </div>
        )}
        <div>
          <h4>{name}</h4>
          <h5 style={{ fontWeight: "normal", lineHeight: "16px" }}>{description}</h5>
        </div>
      </div>
      <div className="flex-end" style={{ alignItems: "center" }}>
        {weight ? <h5 style={{ margin: "0 20px", fontWeight: "normal" }}>{weight}</h5> : <></>}
        <CustomButton
          style={new_price ? { padding: "10px 20px" } : {}}
          name={price ? `${price.toFixed(2) + " ₽"} +` : "+"}
        >
          {new_price && (
            <div className="flex-between" style={{ textAlign: "start", alignItems: "center" }}>
              <div>
                <small>{new_price.toFixed(2) + " ₽"}</small>
                <small
                  className="crossed-line"
                  style={{
                    fontWeight: "normal",
                    fontSize: "12px",
                    opacity: "0.75",
                  }}
                >
                  {price.toFixed(2) + " ₽"}
                </small>
              </div>
              <small style={{ margin: "0 0 0 5px" }}>+</small>
            </div>
          )}
        </CustomButton>
      </div>
    </div>
  );
}
