import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialType = {
  isDark: boolean;
  hash: string;
  table: number;
  orderState: boolean;
  oppenedCategories: number[];
  scrollValueRedux: number;
};

const initialState: InitialType = {
  isDark: false,
  hash: "",
  table: 0,
  orderState: false,
  oppenedCategories: [],
  scrollValueRedux: 0,
};
const root = document.getElementById("root");
function categoriesFilter(arr, elem) {
  if (arr.find((i) => i === elem) > 0) return true;
  else return false;
}
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.isDark = action.payload;
      root?.style.setProperty("background-color", state.isDark ? "#333" : "white");
      document.getElementById("theme")?.setAttribute("content", state.isDark ? "#333" : "#fec11d");
      localStorage.setItem("dark-theme", String(action.payload));
    },
    setHash: (state, action: PayloadAction<string>) => {
      state.hash = action.payload;
      localStorage.setItem("user-hash", action.payload);
    },
    setTableNumber: (state, action: PayloadAction<number>) => {
      state.table = action.payload;
      localStorage.setItem("table-number", String(action.payload));
    },
    setOrderState: (state, action: PayloadAction<boolean>) => {
      state.orderState = action.payload;
      localStorage.setItem("order-state", String(action.payload));
    },
    setOpenCategory: (state, action: PayloadAction<number>) => {
      if (!categoriesFilter(state.oppenedCategories, action.payload)) state.oppenedCategories.push(action.payload);
    },
    deleteOpenCategory: (state, action: PayloadAction<number>) => {
      state.oppenedCategories = state.oppenedCategories.filter((item) => item !== action.payload);
    },
    clearOpenCategory: (state) => {
      state.oppenedCategories = [];
    },
    setScrollValueRedux: (state, action: PayloadAction<number>) => {
      state.scrollValueRedux = action.payload;
    },
  },
});

export const {
  setDarkTheme,
  setHash,
  setTableNumber,
  setOrderState,
  setOpenCategory,
  deleteOpenCategory,
  clearOpenCategory,
  setScrollValueRedux,
} = settingsSlice.actions;

export default settingsSlice.reducer;
