import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "../modules";
import { SvgLogo } from "../assets/svg";
import { removeAllOrders } from "../redux/slices/ordersListSlice";
import { useDispatch } from "react-redux";
export function NotValidHash() {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem("user-hash");
    localStorage.removeItem("table-number");
    localStorage.removeItem("order-state");
    dispatch(removeAllOrders());
    document.title = "Отсканируйте QR код";
    window.scroll(0, 0);
  }, [dispatch]);

  return (
    <Container>
      <div
        className="flex-center"
        style={{
          width: "100%",
          height: "100vh",
          background: `url(${
            process.env.REACT_APP_URL + "/upload/iblock/479/cyk8gzi2uvpanksdz0nvne6lmy0s432e.jpg"
          }) 50% 50% / cover no-repeat`,
        }}
      >
        <h1
          style={{
            position: "absolute",
            alignSelf: "center",
            color: "white",
            backgroundColor: "#0007",
            margin: "15px",
            padding: "40px",
            borderRadius: "25px",
          }}
        >
          {!location.state || location.state === "Не указан код сессии"
            ? "Необходимо отсканировать QR-код"
            : location.state}
        </h1>
      </div>
      <SvgLogo style={{ position: "absolute", width: "100%", top: "20px" }} />
    </Container>
  );
}
