import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { SvgArrow } from "../assets/svg";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export function UpButton() {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  const transitionRef = useRef(null);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    function scrollHandler() {
      setScrollValue(window.scrollY);
    }
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);
  return (
    <CSSTransition nodeRef={transitionRef} in={scrollValue > 400} timeout={300} classNames="up-button" unmountOnExit>
      <SvgArrow
        ref={transitionRef}
        className={isDark ? "up-button dark-button" : "up-button"}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      />
    </CSSTransition>
  );
}
