import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Loader } from "./Loader";

export function Modal({ wifi, message, state, onClick }) {
  const transitionRef = useRef(null);
  state ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "auto");

  return (
    <CSSTransition nodeRef={transitionRef} in={state} timeout={300} classNames="fade-transition" unmountOnExit>
      <div className="modal" ref={transitionRef} onClick={onClick}>
        {wifi ? (
          <>
            <h1>
              Добро пожаловать в зону <mark>бесплатного</mark> <p style={{ whiteSpace: "nowrap" }}>Wi-Fi</p>
            </h1>
            <div>
              <small>Имя сети:</small>
              <input className="modal-input" readOnly value={wifi.login} />
              <small>Пароль:</small>
              <input className="modal-input" readOnly value={wifi.password} />
            </div>
          </>
        ) : message ? (
          <h1 style={{ padding: "30px 0" }}>{message}</h1>
        ) : (
          <Loader />
        )}
      </div>
    </CSSTransition>
  );
}
