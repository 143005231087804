import { useDispatch, useSelector } from "react-redux";
import { Container, MemoizedCartItem, MemoizedFooter, MemoizedHeader, Loader } from "../modules";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setOrders } from "../redux/slices/ordersListSlice";

export function CartPage() {
  const { orders, count } = useSelector((state: RootState) => state.ordersList);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_GET_BASKET!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          dispatch(setOrders(json.data));
        } else if (json.status === 2) navigate("/notvalidhash", { replace: true, state: json.description });
        else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка: " + err.message);
        setMessage("Ошибка при загрузке корзины, обновите страницу");
      });
    window.scroll(0, 0);
    document.title = `Fix Eat | Корзина`;
  }, [navigate, dispatch]);
  return (
    <Container>
      <MemoizedHeader title={"Корзина"} />
      <div style={{ paddingLeft: "80px", marginTop: "38px" }}>
        <h4>Ваш заказ</h4>
        <h5 style={{ margin: "13px 5px 2px" }}>{count}</h5>
      </div>
      <div className="cart-container">
        {orders[0] ? (
          orders.map((item) => <MemoizedCartItem {...item} key={item.id} />)
        ) : message ? (
          <h1 style={{ padding: "30px 0" }}>{message}</h1>
        ) : (
          <Loader />
        )}
      </div>
      <MemoizedFooter />
    </Container>
  );
}
