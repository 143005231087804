import React from "react";
import { BackButton } from "./BackButton";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export function Header({ title }: { title: string }) {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  return (
    <div className={isDark ? "header dark" : "header"}>
      <BackButton />
      <h3>{title}</h3>
    </div>
  );
}
export const MemoizedHeader = React.memo(Header);
