import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container } from "../modules";
import { SvgBoldArrow, SvgLogo } from "../assets/svg";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { removeAllOrders } from "../redux/slices/ordersListSlice";

type MessageData = {
  id: number;
  title: string;
  subtitle?: string;
  background: string;
  icon?: string;
};

export function MessagePage() {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageData, setMessageData] = useState<MessageData>();
  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    if (!!!location.state && !!!params.mes) {
      navigate("/", { replace: true });
    } else if (!!!location.state && !!params.mes)
      fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_MESSAGE! + params.mes)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.status === 0) {
            if (params.mes === "payment-error" || params.mes === "payment-success")
              fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_GET_REFERER!, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ referrer: document.referrer }),
              })
                .then((res) => {
                  return res.json();
                })
                .then((json) => {
                  console.log(json);
                })
                .catch((err) => setMessage("Ошибка: " + err.message));
            setMessageData(json.data);
            window.history.replaceState({}, "");
          } else setMessage("Ошибка: " + json.description);
        })
        .catch((err) => setMessage("Ошибка: " + err.message));
    else
      fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_MESSAGE! + location.state)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.status === 0) {
            setMessageData(json.data);
            window.history.replaceState({}, "");
          } else setMessage("Ошибка: " + json.description);
        })
        .catch((err) => setMessage("Ошибка: " + err.message));
    window.scroll(0, 0);
    dispatch(removeAllOrders());
  }, [location.state, dispatch, navigate, params.mes]);
  return (
    <Container>
      {messageData ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100vh",
              background: `url(${process.env.REACT_APP_URL + messageData.background}) 50% 50% / cover no-repeat`,
            }}
          />
          <SvgLogo style={{ position: "absolute", width: "100%", top: "20px" }} />
          <div className={isDark ? "message dark" : "message"}>
            {messageData.icon ? (
              <img style={{ margin: "5px" }} src={process.env.REACT_APP_URL + messageData.icon} width={50} alt="icon" />
            ) : (
              <></>
            )}
            <h3>{messageData.title}</h3>
            {messageData.subtitle ? <p>{messageData.subtitle}</p> : <></>}
            <div
              style={{
                display: "flex",
                position: "absolute",
                alignItems: "center",
                padding: "0 5px",
                bottom: "50px",
              }}
              onClick={() => navigate(`/`, { replace: true })}
            >
              <SvgBoldArrow style={{ rotate: "270deg", marginRight: "10px", height: "12px" }} /> <h4>На главную</h4>
            </div>
          </div>
        </>
      ) : message ? (
        <h1 style={{ padding: "100px 0" }}>{message}</h1>
      ) : (
        <></>
      )}
    </Container>
  );
}
