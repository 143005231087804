import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Dark, White } from "../assets/img";
import { setDarkTheme } from "../redux/slices/settingsSlice";

export function ThemeSwitch() {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  const dispatch = useDispatch();
  return (
    <div className="theme-switch">
      <img
        src={Dark}
        onClick={() => {
          dispatch(setDarkTheme(!isDark));
        }}
        alt="switch"
      />
      <img
        style={isDark ? { display: "none" } : {}}
        src={White}
        onClick={() => {
          dispatch(setDarkTheme(!isDark));
        }}
        alt="switch"
      />
    </div>
  );
}
