import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Container, CustomButton, Loader } from "../modules";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

type Share = {
  id: number;
  name: string;
  price?: number;
  weight?: string;
  description: string;
  img: string;
  share: boolean;
};

export function SharePage() {
  const navigate = useNavigate();
  const params = useParams();
  const [share, setShare] = useState<Share>();
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  const [message, setMessage] = useState<string>();
  useEffect(() => {
    window.scroll(0, 0);
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API! + "?test-error=1")
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setShare(json.data);
        } else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => setMessage("Ошибка: " + err.message));
  }, [params.share]);
  useEffect(() => {
    document.title = share ? "Fix Eat | " + share.name : "Fix Eat";
  }, [share]);
  return (
    <Container>
      <BackButton
        className="round-button"
        style={{
          height: "20px",
          rotate: "270deg",
        }}
      />
      {share ? (
        <>
          <div
            style={{
              width: "100%",
              height: "50vh",
              background: `url(${share.img}) 50% 50% / cover no-repeat`,
            }}
          />
          <div className={isDark ? "container dark" : "container"} style={{ padding: "20px" }}>
            <small className="flag">АКЦИЯ</small>
            <h1>{share.name}</h1>
            <h5 style={{ borderBottom: "1px solid #7777", paddingBottom: "15px" }}>{share.description}</h5>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <h5>{share.weight ? share.weight : ""}</h5>
              <CustomButton name={share.price ? `${share.price + " ₽"} +` : "Применить"} onClick={() => {}} />
            </div>
            <CustomButton
              name={"Смотреть все акции"}
              style={{ marginTop: "30px" }}
              onClick={() => navigate("/shares")}
            />
          </div>
        </>
      ) : message ? (
        <h1 style={{ padding: "100px 0" }}>{message}</h1>
      ) : (
        <Loader />
      )}
    </Container>
  );
}
