export function Loader({
  styleSVG = {},
  style = {},
}: {
  styleSVG?: React.CSSProperties;
  style?: React.CSSProperties;
}): JSX.Element {
  return (
    <div className="flex-center" style={style}>
      <svg className="loader" style={styleSVG} xmlns="http://www.w3.org/2000/svg" fill="#aaa" viewBox="0 0 100 100">
        <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" />
      </svg>
    </div>
  );
}
