import { Link } from "react-router-dom";
import { SvgClock, SvgEmail, SvgPhone, SvgTelegram, SvgVk } from "../assets/svg";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Loader } from "./Loader";

export function InfoContainer({ info, message }) {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  return (
    <div className="info-container" style={isDark ? { backgroundColor: "#777" } : { backgroundColor: "#eee" }}>
      {info ? (
        <>
          <div className={isDark ? "info-field dark" : "info-field"}>
            <div>
              <SvgClock style={{ margin: "5px" }} />
              <h4 style={{ margin: "16px 5px", letterSpacing: "-0.8px", fontSize: "15px" }}>Приготовление</h4>
              <h4 style={{ fontWeight: "normal", letterSpacing: "-0.4px" }}>{info.working}</h4>
            </div>
            <h4 style={{ marginTop: "15px", fontWeight: "normal", fontSize: "15px" }}>{info.cooking}</h4>
          </div>
          <div className="flex-around" style={{ margin: "55px 0" }}>
            <Link to={"tel:" + info.phone}>
              <SvgPhone />
            </Link>
            <Link to={"mailto:" + info.email}>
              <SvgEmail />
            </Link>
            <Link target="_blank" to={info.vk}>
              <SvgVk />
            </Link>
            <Link target="_blank" to={info.telegram}>
              <SvgTelegram />
            </Link>
          </div>
        </>
      ) : message ? (
        <h1 style={{ padding: "30px 0" }}>{message}</h1>
      ) : (
        <Loader />
      )}
    </div>
  );
}
