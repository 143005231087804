import { SvgCheck } from "../assets/svg";

export function CheckButton({
  onClick,
  checkState,
  img,
  title,
}: {
  onClick: () => void;
  checkState: boolean;
  img: string;
  title: string;
}) {
  return (
    <div
      onClick={onClick}
      className="radio"
      style={checkState ? { opacity: 1, borderColor: "#fed563" } : { opacity: 0.5, borderColor: "#0000" }}
    >
      <div className="radio-icon">
        <img width={30} src={process.env.REACT_APP_URL + img} alt="icon" />
      </div>
      <h4>{title}</h4>
      <SvgCheck style={checkState ? { fill: "#fed563" } : { fill: "#bbb" }} />
    </div>
  );
}
