import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../modules";
import { setHash, setTableNumber } from "../redux/slices/settingsSlice";
import { removeAllOrders } from "../redux/slices/ordersListSlice";

export function QrScan() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    localStorage.removeItem("order-state");
    dispatch(removeAllOrders());
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_CHECK_QR!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "table-code": params.qrcode }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          dispatch(setHash(json.data.hash));
          dispatch(setTableNumber(json.data.table));
          navigate("/", { replace: true });
        } else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => setMessage("Ошибка: " + err.message));
    document.title = "Fix Eat";
  }, [navigate, dispatch, params.qrcode]);
  return <div className="flex-center">{message ? <h1 style={{ padding: "30px 0" }}>{message}</h1> : <Loader />}</div>;
}
