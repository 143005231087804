import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../redux/store";

export function TableNumber() {
  const { isDark, table } = useSelector((state: RootState) => state.settings);
  const location = useLocation();
  return (
    <>
      {table > 0 && !location.pathname.includes("notvalidhash") ? (
        <div className={isDark ? "fixed-icon dark" : "fixed-icon"}>Стол №{table}</div>
      ) : (
        <></>
      )}
    </>
  );
}
