import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
export function CustomButton({
  children,
  name,
  onClick = () => {},
  style = {},
  nameStyle = {},
  disabled = false,
}: {
  children?: any;
  name: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  nameStyle?: React.CSSProperties;
  disabled?: boolean;
}) {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  return (
    <button disabled={disabled} style={style} className={isDark ? "button dark-button" : "button"} onClick={onClick}>
      {children ? children : <small style={nameStyle}>{name}</small>}
    </button>
  );
}
