import { UnmountClosed } from "react-collapse";
import { useEffect, useState } from "react";
import { ItemCard } from "./ItemCard";
import { SvgBoldArrow } from "../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { deleteOpenCategory, setOpenCategory } from "../redux/slices/settingsSlice";

export function CategoryCard({ id, name, count, img, list, subcategory, style = {} }) {
  const { oppenedCategories } = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  function findCategory(arr, elem) {
    if (arr.find((i) => i === elem) > 0) return true;
    else return false;
  }
  const [open, setOpen] = useState(findCategory(oppenedCategories, id));
  const borderCoef = 4;
  useEffect(() => {
    if (open) dispatch(setOpenCategory(id));
    else dispatch(deleteOpenCategory(id));
  }, [open, id, dispatch]);
  return (
    <>
      {subcategory || list ? (
        <>
          <div
            className="category"
            style={style}
            onClick={() => {
              setOpen(!open);
              window.scrollTo(
                open
                  ? { top: window.scrollY - 100, behavior: "smooth" }
                  : { top: window.scrollY + 100, behavior: "smooth" }
              );
            }}
          >
            <img src={process.env.REACT_APP_URL + img} width={50} alt="categoryPicture" />
            <div className="category-text">
              <h4>{name}</h4>
              <h5>{count}</h5>
            </div>
            <SvgBoldArrow
              style={open ? { rotate: "0deg", transition: "300ms" } : { rotate: "180deg", transition: "300ms" }}
            />
          </div>
          <UnmountClosed isOpened={open}>
            {subcategory ? (
              subcategory
                .sort((a, b) => a.sort - b.sort)
                .map((item) => (
                  <CategoryCard
                    style={{ borderLeft: `${borderCoef * item.sub}px solid #fed563` }}
                    {...item}
                    key={item.id}
                  />
                ))
            ) : (
              <></>
            )}
            {list ? (
              list.map((item) => (
                <ItemCard style={{ borderLeft: `${borderCoef * item.sub}px solid #fed563` }} {...item} key={item.id} />
              ))
            ) : (
              <></>
            )}
          </UnmountClosed>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
