import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Order = {
  id: number;
  name: string;
  img: string;
  count: number;
  price: number;
  new_price?: number;
  discount?: string;
  weight: string;
  parameters: { id: number; name: string; priceup: number; new_priceup?: number }[] | [];
  oftentake: { id: number; name: string; price: number; new_price?: number; img: string }[] | [];
};

const initialState: { orders: Order[]; sum: number; new_sum?: number; count: string } = {
  orders: [],
  sum: 0,
  new_sum: 0,
  count: "",
};

export const ordersListSlice = createSlice({
  name: "ordersList",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<Order[]>) => {
      state.orders = action.payload;
    },
    setSum: (state, action: PayloadAction<{ sum: number; new_sum?: number; count: string }>) => {
      state.sum = action.payload.sum;
      state.new_sum = action.payload.new_sum;
      state.count = action.payload.count;
    },
    removeAllOrders: (state) => {
      state.orders = [];
      state.sum = 0;
      state.count = "";
    },
  },
});

export const { setOrders, setSum, removeAllOrders } = ordersListSlice.actions;

export default ordersListSlice.reducer;
