import React from "react";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <div className="footer">
      <Link target="_blank" to={""}>
        Электронное меню Fix Eat
      </Link>
      <Link target="_blank" to={"https://fixeat.ru/privacy"}>
        Политика персональных данных
      </Link>
      <p style={{ justifySelf: "flex-end", color: "#fff", opacity: "0.1" }}>v.{process.env.REACT_APP_VERSION}</p>
    </div>
  );
}
export const MemoizedFooter = React.memo(Footer);
