import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";

export function OrderButton() {
  const { isDark, orderState } = useSelector((state: RootState) => state.settings);
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {orderState &&
      !location.pathname.includes("notvalidhash") &&
      !location.pathname.includes("orders") &&
      !location.pathname.includes("message") ? (
        <div
          className={isDark ? "fixed-icon dark" : "fixed-icon"}
          style={{ top: "50px", height: "30px" }}
          onClick={() => navigate("orders")}
        >
          Ваш заказ
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
