import { Container, MemoizedFooter, MemoizedHeader, Loader, OrderItem, CustomButton } from "../modules";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

type Item = {
  id: number;
  name: string;
  img: string;
  count: string;
  price: number;
  new_price?: number;
  discount?: string;
  weight: string;
  parameters: { id: number; name: string }[] | [];
};
type Order = {
  number: number;
  date: string;
  payment: string;
  items: Item[];
  items_price: number;
  new_items_price?: number;
  items_alco: Item[];
  items_price_alco: number;
  new_items_price_alco?: number;
  sum: number;
  new_sum?: number;
  split: number;
  status: string;
  paymentId: number;
  paymentUrl: string;
};
export function OrderPage() {
  const { isDark } = useSelector((state: RootState) => state.settings);
  const [orders, setOrders] = useState<Order[]>([]);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  useEffect(() => {
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_GET_USER_ORDERS!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setOrders(json.data);
        } else if (json.status === 2) navigate("/notvalidhash", { replace: true, state: json.description });
        else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка: " + err.message);
        setMessage("Ошибка при загрузке заказов, обновите страницу");
      });
    window.scroll(0, 0);
    document.title = `Fix Eat | Заказ`;
  }, [navigate]);
  return (
    <Container>
      <MemoizedHeader title="Ваш заказ" />
      <div className="cart-container">
        {orders[0] ? (
          orders.map((order) => (
            <div className="order-container" style={isDark ? { borderColor: "#aaa" } : {}} key={order.number}>
              <h4>Заказ №{order.number}</h4>
              <h4>Время заказа: {order.date}</h4>
              <h4>Способ оплаты: {order.payment}</h4>
              <h4>Статус: {order.status}</h4>
              {!order.split ? (
                <>
                  {order.items.map((item) => (
                    <OrderItem {...item} key={item.id} />
                  ))}
                  {order.items_alco.map((item) => (
                    <OrderItem {...item} key={item.id} />
                  ))}
                  {order.new_sum ? (
                    <div className="flex-center" style={{ alignItems: "center" }}>
                      <h4>Сумма заказа: </h4>
                      <div style={{ margin: "0 10px" }}>
                        <h4>{order.new_sum.toFixed(2)} ₽</h4>
                        <h4
                          className="crossed-line"
                          style={{
                            fontWeight: "normal",
                          }}
                        >
                          {order.sum.toFixed(2)} ₽
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <h4>Сумма заказа: {order.sum.toFixed(2)} ₽</h4>
                  )}
                  {order.status === "Ожидание оплаты" ? (
                    <CustomButton
                      name="Оплатить"
                      onClick={() => {
                        window.location.href = order.paymentUrl;
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <div className="split-container" style={isDark ? { borderColor: "#aaa" } : {}}>
                    {order.status === "Ожидание оплаты" ? <h4>К оплате онлайн</h4> : <></>}
                    {order.items.map((item) => (
                      <OrderItem {...item} key={item.id} />
                    ))}
                    {order.new_items_price ? (
                      <div className="flex-center" style={{ alignItems: "center" }}>
                        <h4>Сумма: </h4>
                        <div style={{ margin: "0 10px" }}>
                          <h4>{order.new_items_price.toFixed(2)} ₽</h4>
                          <h4
                            className="crossed-line"
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {order.items_price.toFixed(2)} ₽
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <h4>Сумма: {order.items_price.toFixed(2)} ₽</h4>
                    )}
                  </div>
                  <div className="split-container" style={isDark ? { borderColor: "#aaa" } : {}}>
                    {order.status === "Ожидание оплаты" ? (
                      <h4>К оплате картой</h4>
                    ) : (
                      <h4>Эта часть заказа оплачивается картой у официанта</h4>
                    )}
                    {order.items_alco.map((item) => (
                      <OrderItem {...item} key={item.id} />
                    ))}
                    {order.new_items_price_alco ? (
                      <div className="flex-center" style={{ alignItems: "center" }}>
                        <h4>Сумма: </h4>
                        <div style={{ margin: "0 10px" }}>
                          <h4>{order.new_items_price_alco.toFixed(2)} ₽</h4>
                          <h4
                            className="crossed-line"
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {order.items_price_alco.toFixed(2)} ₽
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <h4>Сумма: {order.items_price_alco.toFixed(2)} ₽</h4>
                    )}
                  </div>
                  {order.new_sum ? (
                    <div className="flex-center" style={{ alignItems: "center" }}>
                      <h4>Сумма заказа: </h4>
                      <div style={{ margin: "0 10px" }}>
                        <h4>{order.new_sum.toFixed(2)} ₽</h4>
                        <h4
                          className="crossed-line"
                          style={{
                            fontWeight: "normal",
                          }}
                        >
                          {order.sum.toFixed(2)} ₽
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <h4>Сумма заказа: {order.sum.toFixed(2)} ₽</h4>
                  )}
                  {order.status === "Ожидание оплаты" ? (
                    <CustomButton
                      name="Оплатить"
                      onClick={() => {
                        window.location.href = order.paymentUrl;
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          ))
        ) : message ? (
          <h1 style={{ padding: "30px 0" }}>{message}</h1>
        ) : (
          <Loader />
        )}
      </div>
      <MemoizedFooter />
    </Container>
  );
}
