import { SvgArrow } from "../assets/svg";
import { useLocation, useNavigate } from "react-router-dom";

export function BackButton({
  style = {},
  to = -1,
  className = "",
}: {
  style?: React.CSSProperties;
  to?: any;
  className?: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <SvgArrow
      className={className}
      onClick={() => (location.key === "default" ? navigate("/") : navigate(to))}
      style={style}
    />
  );
}
