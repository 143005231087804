import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../redux/slices/ordersListSlice";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { SvgMinus, SvgPlus, SvgTrashcan } from "../assets/svg";
import { OftenTake } from "./OftenTake";
import { useState } from "react";

export function CartItem({ id, img, price, new_price = 0, discount = "", count, name, weight, parameters, oftentake }) {
  const { isDark, hash } = useSelector((state: RootState) => state.settings);
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function setBasket(hash: string, action: string, item: number) {
    setDisableButton(true);
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_BASKET_ACTION!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "user-hash": hash,
        "basket-action": action,
        "basket-item": item,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setDisableButton(false);
        if (json.status === 0) {
          dispatch(setOrders(json.data));
        } else if (json.status === 2) navigate("/notvalidhash", { replace: true, state: json.description });
        else console.log("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка: " + err.message);
      });
  }
  return (
    <>
      <div className="cart-item">
        <SvgTrashcan
          className="cart-svg"
          onClick={() => {
            setBasket(hash, "delete", id);
          }}
        />
        <div style={{ width: "100%" }}>
          <div className="flex-between" style={{ alignItems: "center", width: "100%" }}>
            <img src={process.env.REACT_APP_URL + img} alt="itemCartPicture" />
            {discount && (
              <div className="discount" style={{ right: "50px", bottom: "50px" }}>
                <span>
                  <p>{"-" + discount}</p>
                </span>
              </div>
            )}
            {new_price ? (
              <div>
                <h4 style={{ whiteSpace: "nowrap" }}>{new_price.toFixed(2)} ₽</h4>
                <h4
                  className="crossed-line"
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "normal",
                  }}
                >
                  {price.toFixed(2)} ₽
                </h4>
              </div>
            ) : (
              <h4 style={{ whiteSpace: "nowrap" }}>{price.toFixed(2)} ₽</h4>
            )}
            <div className="flex-around" style={{ alignItems: "center", width: "97px", marginLeft: "auto" }}>
              <button
                className={isDark ? "counter-button dark-button" : "counter-button"}
                disabled={disableButton}
                onClick={() => {
                  setBasket(hash, "minus", id);
                }}
              >
                <SvgMinus style={isDark ? { fill: "#555", stroke: "#fff" } : { fill: "#fff", stroke: "#000" }} />
              </button>
              <h3 className="flex-center" style={{ flex: "1" }}>
                {count}
              </h3>
              <button
                className={isDark ? "counter-button dark-button" : "counter-button"}
                disabled={disableButton}
                onClick={() => {
                  setBasket(hash, "plus", id);
                }}
              >
                <SvgPlus style={isDark ? { fill: "#555", stroke: "#fff" } : { fill: "#fff", stroke: "#000" }} />
              </button>
            </div>
          </div>
          <div>
            <h3 className="cart-item-text">{name}</h3>
            <h5>{weight}</h5>
            {parameters ? parameters.map((item) => <h5 key={item.id}> {item.name} </h5>) : <></>}
          </div>
        </div>
      </div>
      {oftentake ? (
        <div
          className="often-take-wrapper"
          style={
            isDark
              ? {
                  backgroundColor: "#555",
                }
              : {
                  backgroundColor: "#f7f7f7",
                }
          }
        >
          <h4>С этим товаром часто берут:</h4>
          <div className="flex-wrap" style={{ justifyContent: "center" }}>
            {oftentake.map((item) => (
              <OftenTake key={item.id} {...item} onClick={() => navigate(`/item/${item.id}`)} />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export const MemoizedCartItem = React.memo(CartItem);
