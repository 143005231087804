import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export function Container({ children }) {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 50);
  }, []);
  useEffect(() => {
    if (location.pathname !== path) {
      setPath(location.pathname);
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 150);
    }
  }, [location.pathname, path]);
  return (
    <div
      style={show ? { transform: "translateY(0)", opacity: "1" } : { transform: "translateY(5vh)", opacity: "0" }}
      className={isDark ? "wrapper dark" : "wrapper"}
    >
      {children}
    </div>
  );
}
