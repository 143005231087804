import { useEffect, useRef, useState } from "react";
import { Container, CustomButton, CheckButton, MemoizedFooter, MemoizedHeader, Loader } from "../modules";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { CSSTransition } from "react-transition-group";
import { clearOpenCategory, setOrderState, setScrollValueRedux } from "../redux/slices/settingsSlice";

type PayMethods = { id: number; name: string; img: string }[];

export function OrderingPage() {
  const { isDark, hash } = useSelector((state: RootState) => state.settings);
  const [number, setNumber] = useState<string>("");
  const [checkbox, setCheckbox] = useState<number>(0);
  const [disableButton, setDisableButton] = useState(false);
  const [payMethods, setPayMethods] = useState<PayMethods>([]);
  const [message, setMessage] = useState<string>("");
  const [numberTooltip, setNumberTooltip] = useState(false);
  const [orderSum, setOrderSum] = useState<number>(0);
  const [newOrderSum, setNewOrderSum] = useState<number>(0); // пока не используется
  const [alcoholSum, setAlcoholSum] = useState<number>(0);
  const [newAlcoholSum, setNewAlcoholSum] = useState<number>(0); // пока не используется
  const [totalSum, setTotalSum] = useState<number>(0);
  const [newTotalSum, setNewTotalSum] = useState<number>(0);
  const [sumMessage, setSumMessage] = useState<string>("");
  const [sendMessage, setSendSumMessage] = useState<string>("");
  const transitionRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setDisableButton(true);
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_CHECK_HASH!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_GET_PAYMENT!)
            .then((res) => {
              return res.json();
            })
            .then((json) => {
              if (json.status === 0) {
                setDisableButton(false);
                setPayMethods(json.data);
                json.data.filter((el) => el.id === 4)[0] ? setCheckbox(4) : setCheckbox(0);
              } else setMessage("Ошибка: " + json.description);
            })
            .catch((err) => {
              console.log("Ошибка: " + JSON.stringify(err.message));
              setMessage("Ошибка при загрузке способов оплаты, обновите страницу");
            });
          fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_GET_SUMS!, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
          })
            .then((res) => {
              return res.json();
            })
            .then((json) => {
              if (json.status === 0) {
                setOrderSum(Number(json.data.not_alco_sum));
                setNewOrderSum(Number(json.data.new_not_alco_sum));
                setAlcoholSum(Number(json.data.alco_sum));
                setNewAlcoholSum(Number(json.data.new_alco_sum));
                setTotalSum(Number(json.data.total_sum));
                setNewTotalSum(Number(json.data.new_total_sum));
              } else setSumMessage("Ошибка: " + json.description);
            })
            .catch((err) => {
              console.log("Ошибка: " + JSON.stringify(err.message));
              setSumMessage("Ошибка при загрузке суммы заказа, обновите страницу");
            });
        } else if (json.status === 2) {
          navigate("/notvalidhash", { replace: true, state: json.description });
        } else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => console.log("Ошибка: " + err.message));
    window.scroll(0, 0);
    document.title = `Fix Eat | Оформление`;
    dispatch(clearOpenCategory());
    dispatch(setScrollValueRedux(0));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function sendOrder() {
    type PostOrders = { "user-hash": string; "user-payment": number; "phone-number"?: string };
    let postOrders: PostOrders = { "user-hash": hash, "user-payment": checkbox };
    if (number.length === 11) postOrders["phone-number"] = number;
    setDisableButton(true);
    setSendSumMessage("");
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_CREATE_ORDER!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postOrders),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setDisableButton(false);
        if (json.status === 0) {
          dispatch(setOrderState(true));
          if (json.data) {
            window.location.href = json.data.url;
          } else
            navigate(`/message`, {
              replace: true,
              state: "order-created",
            });
        } else if (json.status === 2) navigate("/notvalidhash", { replace: true, state: json.description });
        else if (json.status === 1) {
          navigate(`/message`, {
            replace: true,
            state: "order-error",
          });
        } else {
          console.log("Ошибка: " + json.description);
          setSendSumMessage("Ошибка: " + json.description);
        }
      })
      .catch((err) => {
        setDisableButton(false);
        console.log("Ошибка: " + err.message);
        setSendSumMessage("Ошибка при оформлении заказа");
      });
  }
  return (
    <Container>
      <MemoizedHeader title={"Оформление заказа"} />
      <div className="flex-center" style={{ flexDirection: "column", padding: "25px 10px" }}>
        <div style={{ display: "flex" }}>
          <h4 style={{ margin: "3px 34px" }}>1</h4>
          <div style={{ display: "grid" }}>
            <h4 style={{ margin: "3px 5px" }}>Ваш телефон</h4>
            <CSSTransition
              nodeRef={transitionRef}
              in={numberTooltip}
              timeout={300}
              classNames="number-tooltip-trasition"
              unmountOnExit
            >
              <h5 ref={transitionRef} className="number-tooltip">
                Номер введён не полностью
              </h5>
            </CSSTransition>
            <div style={{ display: "flex", margin: "33px 0 9px 8px" }}>
              <form
                onSubmit={(e) => {
                  if (number.length === 11 && document.getElementById("number-input")) {
                    document.getElementById("number-input")?.blur();
                    setNumberTooltip(false);
                  } else setNumberTooltip(true);
                  e.preventDefault();
                }}
                onBlur={(e) => {
                  if (number.length === 11 && document.getElementById("number-input")) {
                    setNumberTooltip(false);
                  } else setNumberTooltip(true);
                  e.preventDefault();
                }}
              >
                <ReactInputMask
                  id="number-input"
                  className={isDark ? "input dark" : "input"}
                  type="tel"
                  autoComplete="off"
                  placeholder="+7(___)___-__-__ введите номер"
                  mask="+7(999)999-99-99"
                  maskChar="_"
                  onChange={(e) => {
                    setNumber(e.target.value.replace(/[^0-9]/gi, ""));
                  }}
                  value={number}
                />
              </form>
            </div>
            <p style={{ fontSize: "15px", margin: "13px 5px", letterSpacing: "-0.5px", lineHeight: "18px" }}>
              Необязательно к заполнению.
              <br />
              При указании номера телефона Вы сможете просматривать историю своих заказов.
            </p>
          </div>
        </div>
        {!!totalSum ? (
          <div style={{ display: "flex", paddingTop: "30px" }}>
            <h4 style={{ margin: "3px 34px" }}>2</h4>
            <div style={{ display: "grid", width: "100%", paddingRight: "7px" }}>
              <h4 style={{ margin: "3px 5px" }}>Оплата</h4>
              {!!alcoholSum && !!orderSum && checkbox === 3 ? (
                <>
                  <div style={{ margin: "11px 0 5.5px" }} className="flex-between">
                    <h4 style={{ fontWeight: "normal" }}>Заказ</h4> <h4>{orderSum.toFixed(2)} ₽</h4>
                  </div>
                  <div style={{ margin: "5.5px 0 11px" }} className="flex-between">
                    <h4 style={{ fontWeight: "normal" }}>Алкоголь</h4> <h4>{alcoholSum.toFixed(2)} ₽</h4>
                  </div>
                </>
              ) : (
                <div style={{ margin: "11px 0" }} className="flex-between">
                  <h4 style={{ fontWeight: "normal" }}>Заказ</h4> <h4>{totalSum.toFixed(2)} ₽</h4>
                </div>
              )}
              {newTotalSum ? (
                <div className="flex-between">
                  <h4 style={{ fontWeight: "normal" }}>Скидка</h4> <h4>{(totalSum - newTotalSum).toFixed(2)} ₽</h4>
                </div>
              ) : (
                <></>
              )}
              <span
                style={{
                  margin: "23px 0",
                  borderTop: "2px dotted #7777",
                }}
              />
              <div className="flex-between">
                <h4 style={{ fontWeight: "normal" }}>Итого</h4>
                {newTotalSum ? <h4>{newTotalSum.toFixed(2)} ₽</h4> : <h4>{totalSum.toFixed(2)} ₽</h4>}
              </div>
            </div>
          </div>
        ) : sumMessage ? (
          <h1 style={{ padding: "30px 0" }}>{sumMessage}</h1>
        ) : (
          <Loader />
        )}
        <h4 style={{ textAlign: "center", marginTop: "30px" }}>Выберите способ оплаты</h4>
        <div className="check-buttons">
          {payMethods[0] ? (
            payMethods.map((item) => (
              <CheckButton
                title={item.name}
                onClick={() => {
                  if (!(!!!orderSum && !!alcoholSum && item.id === 3)) setCheckbox(item.id);
                }}
                checkState={checkbox === item.id}
                img={item.img}
                key={item.id}
              />
            ))
          ) : message ? (
            <h1 style={{ padding: "30px 0" }}>{message}</h1>
          ) : (
            <Loader />
          )}
        </div>
        {checkbox === 3 && !!alcoholSum ? (
          <h5 style={{ textAlign: "center", height: "40px" }}>
            Алкогольные напитки необходимо оплатить отдельно с помощью банковской карты
          </h5>
        ) : !!!orderSum && !!alcoholSum ? (
          <h5 style={{ textAlign: "center", height: "40px" }}>
            Алкогольные напитки можно оплатить только наличными или с помощью банковской карты
          </h5>
        ) : (
          <div style={{ height: "50px" }} />
        )}
        <CustomButton
          style={{
            alignSelf: "center",
            padding: "20px",
            width: "75%",
            borderRadius: "30px",
          }}
          disabled={disableButton || checkbox === 0 || !!!totalSum}
          nameStyle={{ fontSize: "small" }}
          name={checkbox === 3 ? "Перейти к оплате" : "Оформить заказ"}
          onClick={() => {
            sendOrder();
          }}
        />
        {sendMessage ? <h1 style={{ height: "65px" }}>{sendMessage}</h1> : <div style={{ height: "75px" }} />}
      </div>
      <MemoizedFooter />
    </Container>
  );
}
