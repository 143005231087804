export function OrderItem({ img, price, new_price = 0, discount = "", count, name, weight, parameters }) {
  return (
    <>
      <div className="order-item">
        <div style={{ display: "flex" }}>
          <img
            style={{ width: "125px", height: "125px" }}
            src={process.env.REACT_APP_URL + img}
            alt="itemCartPicture"
          />
          {discount && (
            <div className="discount" style={{ right: "50px", bottom: "50px" }}>
              <span>
                <p>{"-" + discount}</p>
              </span>
            </div>
          )}
        </div>
        <h3 style={{ fontWeight: "normal" }}>{name}</h3>
        <h5>{weight}</h5>
        {parameters ? (
          parameters.map((item: { id: number; name: string }) => <h5 key={item.id}> {item.name} </h5>)
        ) : (
          <></>
        )}
        <h5>{count} шт.</h5>
        {new_price ? (
          <div>
            <h4 style={{ whiteSpace: "nowrap" }}>{new_price.toFixed(2)} ₽</h4>
            <h4
              className="crossed-line"
              style={{
                whiteSpace: "nowrap",
                fontWeight: "normal",
              }}
            >
              {price.toFixed(2)} ₽
            </h4>
          </div>
        ) : (
          <h4 style={{ whiteSpace: "nowrap" }}>{price.toFixed(2)} ₽</h4>
        )}
      </div>
    </>
  );
}
