import React from "react";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { SvgCheckGreen, SvgCheckRed } from "../assets/svg";

export function MessageLine({ state, message, err }: { state: boolean; message: string; err: boolean }) {
  const transitionRef = useRef(null);
  return (
    <CSSTransition nodeRef={transitionRef} in={state} timeout={300} classNames="message-line-trasition" unmountOnExit>
      <div ref={transitionRef} className="message-line">
        <div className="message-line-inner">
          {!err ? <SvgCheckGreen /> : <SvgCheckRed />}
          <h4>{message}</h4>
        </div>
      </div>
    </CSSTransition>
  );
}
export const MemoizedMessageLine = React.memo(MessageLine);
