import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { RootState } from "../redux/store";
import { useEffect, useRef } from "react";
import { SvgTicket } from "../assets/svg";
import { setSum } from "../redux/slices/ordersListSlice";

export function CartLine() {
  const { isDark } = useSelector((state: RootState) => state.settings);
  const { sum, new_sum, count, orders } = useSelector((state: RootState) => state.ordersList);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transitionRef = useRef(null);
  const bool = location.pathname.includes("cart");
  useEffect(() => {
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_GET_BASKET_INFO!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          dispatch(setSum({ sum: json.data.sum, new_sum: json.data.new_sum, count: json.data.count }));
          if (
            !json.data.sum &&
            !location.pathname.includes("item") &&
            !location.pathname.includes("message") &&
            !location.pathname.includes("orders")
          ) {
            navigate("/", { replace: true });
          }
        } else console.log("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка: " + err.message);
      });
  }, [orders]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CSSTransition
      nodeRef={transitionRef}
      in={
        !!sum &&
        !location.pathname.includes("message") &&
        !location.pathname.includes("ordering") &&
        !location.pathname.includes("notvalidhash")
      }
      timeout={200}
      classNames="cart-line-trasition"
      unmountOnExit
    >
      <div
        ref={transitionRef}
        className="cart-line"
        style={isDark ? { backgroundColor: "#aaa" } : { backgroundColor: "#fff" }}
      >
        {new_sum ? (
          bool ? (
            <>
              <SvgTicket />
              <div>
                <h2>{new_sum.toFixed(2)} ₽</h2>
                <h2
                  className="crossed-line"
                  style={{
                    fontWeight: "normal",
                  }}
                >
                  {sum.toFixed(2)} ₽
                </h2>
              </div>
            </>
          ) : (
            <>
              <h5>{count}</h5>
              <div>
                <h2>{new_sum.toFixed(2)} ₽</h2>
                <h2
                  className="crossed-line"
                  style={{
                    fontWeight: "normal",
                  }}
                >
                  {sum.toFixed(2)} ₽
                </h2>
              </div>
            </>
          )
        ) : (
          <>
            <h5>{count}</h5> <h2>{sum.toFixed(2)} ₽</h2>
          </>
        )}
        <Link
          to={bool ? `/ordering` : `/cart`}
          className={isDark ? "button dark" : "button"}
          style={{ marginRight: "20px", padding: "20px 35px", borderRadius: "30px" }}
        >
          <small>{bool ? "Оформить" : "Корзина"}</small>
        </Link>
      </div>
    </CSSTransition>
  );
}
