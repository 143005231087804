import { configureStore } from "@reduxjs/toolkit";
import settingsSlice from "./slices/settingsSlice";
import ordersListSlice from "./slices/ordersListSlice";

export const store = configureStore({
  reducer: {
    settings: settingsSlice,
    ordersList: ordersListSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
