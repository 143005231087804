import { Route, Routes } from "react-router-dom";
import { Container, CartLine, UpButton, Header, TableNumber, OrderButton } from "./modules";
import {
  MainPage,
  CartPage,
  OrderingPage,
  MessagePage,
  ItemPage,
  SharesPage,
  SharePage,
  QrScan,
  NotValidHash,
  OrderPage,
} from "./pages";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDarkTheme, setHash, setOrderState, setTableNumber } from "./redux/slices/settingsSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const userHash = localStorage.getItem("user-hash");
    dispatch(setHash(userHash != null ? userHash : ""));
    const tableNumber = localStorage.getItem("table-number");
    dispatch(setTableNumber(tableNumber != null ? Number(tableNumber) : 0));
    const orderState = localStorage.getItem("order-state");
    dispatch(setOrderState(orderState != null ? JSON.parse(orderState) : false));
    const darkTheme = localStorage.getItem("dark-theme");
    dispatch(setDarkTheme(darkTheme != null ? JSON.parse(darkTheme) : false));
  }, [dispatch]);
  return (
    <>
      <UpButton />
      <TableNumber />
      <OrderButton />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/item/:id" element={<ItemPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/orders" element={<OrderPage />} />
        <Route path="/ordering" element={<OrderingPage />} />
        <Route path="/shares" element={<SharesPage />} />
        <Route path="/shares/:share" element={<SharePage />} />
        <Route path="/message" element={<MessagePage />} />
        <Route path="/message/:mes" element={<MessagePage />} />
        <Route path="/qr/:qrcode" element={<QrScan />} />
        <Route path="/notvalidhash" element={<NotValidHash />} />
        <Route
          path="*"
          element={
            <Container>
              <Header title={"Страница не найдена"} />
            </Container>
          }
        />
      </Routes>
      <CartLine />
    </>
  );
}

export default App;
