import { useSelector } from "react-redux";
import { SvgPlus } from "../assets/svg";
import { RootState } from "../redux/store";

export function OftenTake({ onClick, img, name, price, new_price = 0, discount = "" }) {
  const isDark = useSelector((state: RootState) => state.settings.isDark);
  return (
    <div onClick={onClick} className={isDark ? "often-take dark" : "often-take"}>
      <img
        style={{ marginRight: "10px", width: "60px", height: "60px", objectFit: "cover", borderRadius: "10px" }}
        src={process.env.REACT_APP_URL + img}
        alt="radioPicture"
      />
      {discount && (
        <div className="discount" style={{ bottom: "32px", right: "44px" }}>
          <span style={{ minHeight: "25px", minWidth: "25px" }}>
            <p style={{ fontSize: "10px" }}>{"-" + discount}</p>
          </span>
        </div>
      )}
      <div className="flex-between" style={{ flex: "1", flexDirection: "column" }}>
        <small style={{ fontWeight: "normal", letterSpacing: "0.2px" }}>{name}</small>
        {new_price ? (
          <div className="flex-between">
            <small
              className="crossed-line"
              style={{
                margin: "0",
              }}
            >
              {price.toFixed(2)} ₽
            </small>
            <small style={{ margin: "0" }}>{new_price.toFixed(2)} ₽</small>
            <SvgPlus style={{ fill: "#fec11d", stroke: "#000", width: "20px", alignSelf: "flex-end" }} />
          </div>
        ) : (
          <div className="flex-between">
            <small style={{ margin: "0" }}>{price.toFixed(2)} ₽</small>{" "}
            <SvgPlus style={{ fill: "#fec11d", stroke: "#000", width: "20px", alignSelf: "flex-end" }} />
          </div>
        )}
      </div>
    </div>
  );
}
