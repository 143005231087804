import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BackButton,
  Container,
  CustomButton,
  Loader,
  MemoizedFooter,
  MemoizedMessageLine,
  OftenTake,
} from "../modules";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSum } from "../redux/slices/ordersListSlice";
import { createPortal } from "react-dom";

type Parameters = { id: number; name: string; priceup: number; new_priceup?: number }[];
type Weight = { id: number; value: string; price: number; new_price?: number };
type Item = {
  id: number;
  name: string;
  discount?: string;
  weights: { id: number; value: string; price: number; new_price?: number }[];
  description: string;
  img: string;
  parameters?: { id: number; name: string; priceup: number; new_priceup?: number }[];
  oftentake?: {
    id: number;
    name: string;
    price: number;
    new_price?: number;
    discount?: string;
    img: string;
  }[];
};

export function ItemPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [item, setItem] = useState<Item>();
  const { isDark, hash } = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  const [parameters, setParameters] = useState<Parameters>([]);
  const [weight, setWeight] = useState<Weight>({ id: 0, value: "", price: 0 });
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [diffPriceup, setDiffPriceup] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isErr, setIsErr] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [tooltip, setTooltip] = useState(false);
  useEffect(() => {
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_DETAIL! + params.id, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setItem(json.data);
          setTotalPrice(0);
          setDiffPriceup(0);
          setParameters([]);
          if (json.data.drink === 1 && json.data.weights.length > 2) {
            setWeight(json.data.weights[Math.round(json.data.weights.length / 2 - 1)]);
          } else setWeight(json.data.weights[0]);
        } else if (json.status === 2) navigate("/notvalidhash", { replace: true, state: json.description });
        else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => setMessage("Ошибка: " + err.message));
  }, [params.id, navigate]);
  useEffect(() => {
    document.title = item ? "Fix Eat | " + item.name : "Fix Eat";
    window.scroll(0, 0);
  }, [item]);
  useEffect(() => {
    if (tooltip) setTimeout(() => setTooltip(false), 3000);
  }, [tooltip]);
  function addParam(param) {
    setParameters((prev) => [...prev, param]);
    setParameters((prev) => prev.sort((a, b) => (a["id"] > b["id"] ? 1 : -1)));
    if (param.new_priceup) {
      setTotalPrice((prev) => prev + param.new_priceup);
      setDiffPriceup((prev) => prev + (param.priceup - param.new_priceup));
    } else setTotalPrice((prev) => prev + param.priceup);
  }
  function removeParam(param) {
    setParameters((prev) =>
      prev.filter((item) => {
        return item.id !== param.id;
      })
    );
    if (param.new_priceup) {
      setTotalPrice((prev) => prev - param.new_priceup);
      setDiffPriceup((prev) => prev - (param.priceup - param.new_priceup));
    } else setTotalPrice((prev) => prev - param.priceup);
  }
  function addToBasket() {
    setDisableButton(true);
    type PostBasket = { "user-hash": string; products: { id: number; quantity: number; parent_id?: number }[] };
    let postBasket: PostBasket = {
      "user-hash": hash,
      products: [{ id: weight.id, quantity: 1 }],
    };
    if (parameters) {
      for (let i = 0; i < parameters.length; i++) {
        postBasket.products.push({ id: parameters[i].id, quantity: 1, parent_id: weight.id });
      }
    }
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_ADD_TO_BASKET!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postBasket),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setDisableButton(false);
        if (json.status === 0) {
          dispatch(setSum({ sum: json.data.sum, new_sum: json.data.new_sum, count: json.data.count }));
          setIsErr(false);
          setMessage(json.description);
          setTooltip(true);
        } else if (json.status === 2) navigate("/notvalidhash", { replace: true, state: json.description });
        else {
          setIsErr(true);
          setMessage("Ошибка: " + json.description);
          setTooltip(true);
        }
      })
      .catch((err) => {
        setDisableButton(false);
        console.log("Ошибка: " + err.message);
        setIsErr(true);
        setMessage("Ошибка при добавлении в корзину. Обновите страницу");
        setTooltip(true);
      });
  }
  return (
    <Container>
      {createPortal(
        <MemoizedMessageLine state={tooltip} message={message} err={isErr} />,
        document.getElementById("root")!
      )}
      {createPortal(
        <BackButton
          className="round-button"
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            height: "20px",
            rotate: "270deg",
            zIndex: "2",
          }}
        />,
        document.getElementById("root")!
      )}
      {item ? (
        <>
          <div
            style={{
              width: "100%",
              height: "50vh",
              minHeight: "300px",
              background: `url(${process.env.REACT_APP_URL + item.img}) 50% 50% / cover no-repeat`,
            }}
          />
          <div className={isDark ? "container item dark" : "container item"}>
            {item.discount && (
              <div className="discount" style={{ bottom: "95px" }}>
                <span style={{ minHeight: "45px", minWidth: "45px" }}>
                  <p style={{ fontSize: "17px", opacity: "1" }}>{"-" + item.discount}</p>
                </span>
              </div>
            )}
            <h1>{item.name}</h1>
            <p>{item.description}</p>
            <span
              style={{
                margin: "25px 0 23px",
                borderTop: "1px solid #0001",
              }}
            />
            {item.weights ? (
              <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", marginBottom: "17px" }}>
                {item.weights.map((item) => (
                  <button
                    className={weight.value === item.value ? "weight-button weight-button-active" : "weight-button"}
                    style={isDark ? { color: "#fff" } : { color: "#000" }}
                    onClick={() => {
                      setWeight(item);
                    }}
                    key={item.value}
                  >
                    <small>{item.value}</small>
                    {item.new_price ? (
                      <div className="flex-between">
                        <p style={{ color: "#58704f", opacity: "1" }}>{item.new_price.toFixed(2) + " ₽"}</p>
                        <p
                          className="crossed-line"
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          {item.price.toFixed(2) + " ₽"}
                        </p>
                      </div>
                    ) : (
                      <p>{item.price.toFixed(2) + " ₽"} </p>
                    )}
                  </button>
                ))}
              </div>
            ) : (
              <></>
            )}
            {item.parameters ? (
              <>
                <h4 style={{ margin: "10px 0 ", letterSpacing: "-0.6px" }}>Добавьте к блюду:</h4>
                <div className="flex-wrap">
                  {item.parameters.map((param) => (
                    <button
                      onClick={() => {
                        parameters.find((item) => item.id === param.id) ? removeParam(param) : addParam(param);
                      }}
                      className={isDark ? "button param-dark" : "button param"}
                      style={
                        parameters.find((item) => item.id === param.id)
                          ? { backgroundColor: "#FCD76D" }
                          : { color: "#A9A9AB", backgroundColor: "#ECECEC" }
                      }
                      key={param.id}
                    >
                      {param.new_priceup ? (
                        <div className="flex-between">
                          <p>{param.name + " " + param.new_priceup.toFixed(2) + " ₽"}</p>
                          <p
                            style={
                              parameters.find((item) => item.id === param.id)
                                ? {
                                    textDecorationLine: "line-through",
                                    WebkitTextDecorationLine: "line-through",
                                    textDecorationColor: "#e97607",
                                    WebkitTextDecorationColor: "#e97607",
                                    textDecorationThickness: "2px",
                                  }
                                : {
                                    textDecorationLine: "line-through",
                                    WebkitTextDecorationLine: "line-through",
                                    textDecorationColor: "#A9A9AB",
                                    WebkitTextDecorationColor: "#A9A9AB",
                                    textDecorationThickness: "2px",
                                  }
                            }
                          >
                            {param.priceup.toFixed(2) + " ₽"}
                          </p>
                        </div>
                      ) : param.priceup !== null && param.priceup !== 0 ? (
                        param.name + " " + param.priceup.toFixed(2) + " ₽"
                      ) : (
                        param.name
                      )}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <CustomButton
                name={`Добавить в корзину ${(totalPrice + weight.price).toFixed(2)} ₽`}
                disabled={disableButton}
                style={weight.new_price ? { padding: "10px 20px" } : { padding: "15px 20px" }}
                onClick={() => {
                  addToBasket();
                }}
              >
                {weight.new_price && (
                  <div className="flex-between" style={{ textAlign: "start", alignItems: "center" }}>
                    <small style={{ marginRight: "5px" }}>Добавить в корзину</small>
                    <div>
                      <small>{(totalPrice + weight.new_price).toFixed(2) + " ₽"}</small>
                      <small
                        className="crossed-line"
                        style={{
                          fontWeight: "normal",
                          fontSize: "12px",
                          opacity: "0.75",
                        }}
                      >
                        {(totalPrice + diffPriceup + weight.price).toFixed(2) + " ₽"}
                      </small>
                    </div>
                  </div>
                )}
              </CustomButton>
            </div>
          </div>
          {item.oftentake ? (
            <div
              style={
                isDark
                  ? {
                      backgroundColor: "#555",
                      padding: "10px",
                    }
                  : {
                      backgroundColor: "#ddd",
                      padding: "10px",
                    }
              }
            >
              <h4 style={{ padding: "5px" }}>С этим товаром часто берут:</h4>
              <div className="flex-wrap" style={{ justifyContent: "center" }}>
                {item.oftentake.map((item) => (
                  <OftenTake
                    key={item.id}
                    {...item}
                    onClick={() => {
                      navigate(`/item/${item.id}`);
                    }}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : message ? (
        <h1 style={{ padding: "100px 0" }}>{message}</h1>
      ) : (
        <Loader style={{ minHeight: "100vh" }} />
      )}
      <MemoizedFooter />
    </Container>
  );
}
