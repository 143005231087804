import { CustomButton, CategoryCard, Loader, Modal, MemoizedFooter, InfoContainer, ThemeSwitch } from "../modules";
import { Container } from "../modules/Container";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { SvgLogo } from "../assets/svg";
import { CSSTransition } from "react-transition-group";
import "swiper/css";
import "swiper/css/pagination";
import { setScrollValueRedux } from "../redux/slices/settingsSlice";

type Data = {
  id: number;
  name: string;
  count: string;
  img: string;
  subcategory:
    | {
        id: number;
        sub: number;
        name: string;
        count: string;
        img: string;
        subcategory: Object[] | null;
        list: Object[] | null;
      }[]
    | null;
  list:
    | {
        id: string | number;
        name: string;
        price: number;
        new_price?: number;
        discount?: string;
        weight: string;
        description: string;
        img: string;
      }[]
    | null;
}[];
type Slider = { id: number; img: string }[];
type Info = { working: string; cooking: string };
type WiFi = { login: string; password: string };

export function MainPage() {
  const { isDark, scrollValueRedux, orderState } = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<Data>();
  const [sliderData, setSliderData] = useState<Slider>([]);
  const [messageSlider, setMessageSlider] = useState<string>("");
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [info, setInfo] = useState<Info>();
  const [messageInfo, setMessageInfo] = useState<string>("");
  const [wifi, setWifi] = useState<WiFi>();
  const [messageWifi, setMessageWifi] = useState<string>("");
  const modalTransitionRef = useRef(null);
  const [modalState, setModalState] = useState(false);
  useEffect(() => {
    modalState ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "auto");
  }, [modalState]);
  useEffect(() => {
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_CHECK_HASH!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          fetches();
        } else if (json.status === 2) navigate("/notvalidhash", { replace: true, state: json.description });
        else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => console.log("Ошибка: " + err.message));
    function scrollHandler() {
      dispatch(setScrollValueRedux(window.scrollY));
    }
    document.title = "Fix Eat";
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function fetches() {
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_SWIPER!)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setSliderData(json.data);
        } else setMessageSlider("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка свайпера: " + err.message);
        setMessageSlider("Ошибка при загрузке акций, обновите страницу");
      });
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_SECTIONS!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setData(json.data.sort((a, b) => a.sort - b.sort));
          setTimeout(() => window.scrollTo({ top: scrollValueRedux, behavior: "smooth" }), 50);
        } else setMessage("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка cекций: " + err.message);
        setMessage("Ошибка при загрузке товаров, обновите страницу");
      });
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_WORKING_TIME!)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setInfo(json.data);
        } else setMessageInfo("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка графика работы: " + err.message);
        setMessageInfo("Ошибка при загрузке графика работы, обновите страницу");
      });
    fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_WIFI!)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status === 0) {
          setWifi(json.data);
        } else setMessageWifi("Ошибка: " + json.description);
      })
      .catch((err) => {
        console.log("Ошибка wifi: " + err.message);
        setMessageWifi("Ошибка при загрузке данных wifi, обновите страницу");
      });
    window.scroll(0, 0);
  }
  const swiperSize = 370;
  return (
    <Container>
      {createPortal(
        <Modal wifi={wifi} message={messageWifi} state={modal} onClick={() => setModal(false)} />,
        document.body
      )}
      {createPortal(
        <CSSTransition
          nodeRef={modalTransitionRef}
          in={modalState}
          timeout={300}
          classNames="fade-transition"
          unmountOnExit
        >
          <div className="modal" ref={modalTransitionRef} onClick={() => setModalState(false)}>
            <CustomButton
              style={{
                height: "55px",
                width: "230px",
                borderRadius: "30px",
                padding: "10px 43px",
                whiteSpace: "nowrap",
                backgroundColor: "#FFC11E",
                color: "#000",
              }}
              name={"Вызвать официанта"}
              nameStyle={{ fontSize: "small" }}
              onClick={() => {
                fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_CALL_WAITER!, {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((json) => {
                    if (json.status === 0) {
                      navigate(`/message`, {
                        state: "waiter-called",
                      });
                    } else if (json.status === 2) navigate("/notvalidhash", { state: json.description });
                    else if (json.status === 1)
                      navigate(`/message`, {
                        state: "session-closed",
                      });
                    else console.log("Ошибка: " + json.description);
                  })
                  .catch((err) => console.log("Ошибка при вызове официанта: " + err.message));
              }}
            />
            <CustomButton
              style={{
                height: "55px",
                width: "230px",
                borderRadius: "30px",
                padding: "10px 43px",
                whiteSpace: "nowrap",
                backgroundColor: "#3da422",
                color: "#fff",
              }}
              name={"Оплатить заказ"}
              nameStyle={{ fontSize: "small" }}
              onClick={() => {
                fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_CALL_WAITER!, {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash"), payment: 1 }),
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((json) => {
                    if (json.status === 0) {
                      navigate(`/message`, {
                        state: "waiter-called",
                      });
                    } else if (json.status === 2) navigate("/notvalidhash", { state: json.description });
                    else if (json.status === 1)
                      navigate(`/message`, {
                        state: "session-closed",
                      });
                    else console.log("Ошибка: " + json.description);
                  })
                  .catch((err) => console.log("Ошибка при вызове официанта: " + err.message));
              }}
            />
          </div>
        </CSSTransition>,
        document.body
      )}
      <SvgLogo style={{ position: "absolute", left: "10px", top: "10px", zIndex: "2" }} />
      <Swiper
        style={{ height: `${swiperSize}px` }}
        slidesPerView={1}
        loop={true}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<div class=${className}><span/></div>`;
          },
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
      >
        {sliderData[0] ? (
          sliderData.map((item) => (
            <SwiperSlide
              style={{
                background: `url(${process.env.REACT_APP_URL + item.img}) 50% 50% / cover no-repeat`,
              }}
              onClick={() => {
                //navigate(`/shares/${item.id}`);
              }}
              key={item.id}
            />
          ))
        ) : (
          <SwiperSlide>
            {messageSlider ? (
              <h1 style={{ paddingTop: swiperSize / 3 + "px" }}>{messageSlider}</h1>
            ) : (
              <Loader style={{ marginTop: swiperSize / 3 + "px" }} />
            )}
          </SwiperSlide>
        )}
      </Swiper>
      <div
        className="flex-center"
        style={{
          position: "absolute",
          width: "100%",
          zIndex: "1",
          top: `${swiperSize - 105}px`,
        }}
      >
        <CustomButton
          style={{
            width: "55px",
            height: "55px",
            padding: "10px",
            borderRadius: "30px",
            whiteSpace: "nowrap",
            backgroundColor: "#FFC11E",
            color: "#000",
          }}
          name={"Wi-Fi"}
          nameStyle={{ fontSize: "small" }}
          onClick={() => {
            setModal(!modal);
          }}
        />
        <CustomButton
          style={{
            borderRadius: "30px",
            padding: "10px 38px",
            whiteSpace: "nowrap",
            backgroundColor: "#FFC11E",
            color: "#000",
          }}
          name={"Вызвать официанта"}
          nameStyle={{ fontSize: "small" }}
          onClick={
            orderState
              ? () => setModalState(true)
              : () => {
                  fetch(process.env.REACT_APP_URL + process.env.REACT_APP_API_CALL_WAITER!, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ "user-hash": localStorage.getItem("user-hash") }),
                  })
                    .then((res) => {
                      return res.json();
                    })
                    .then((json) => {
                      if (json.status === 0) {
                        navigate(`/message`, {
                          state: "waiter-called",
                        });
                      } else if (json.status === 2) navigate("/notvalidhash", { state: json.description });
                      else if (json.status === 1)
                        navigate(`/message`, {
                          state: "session-closed",
                        });
                      else console.log("Ошибка: " + json.description);
                    })
                    .catch((err) => console.log("Ошибка при вызове официанта: " + err.message));
                }
          }
        />
        <ThemeSwitch />
      </div>
      <div className={isDark ? "container dark" : "container"}>
        <div className="category-container">
          {data ? (
            data.map((item) => <CategoryCard {...item} key={item.id} />)
          ) : message ? (
            <h1 style={{ padding: "30px 0" }}>{message}</h1>
          ) : (
            <Loader />
          )}
        </div>
        <InfoContainer info={info} message={messageInfo} />
      </div>
      <MemoizedFooter />
    </Container>
  );
}
